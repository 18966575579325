import { IoPaperPlaneOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { LiaDiscord } from "react-icons/lia";
import { PiMediumLogo } from "react-icons/pi";
import { CgMoreO } from "react-icons/cg";
import { FaXTwitter } from "react-icons/fa6";
import "../../scss/landing.scss";
import { useEffect, useState } from "react";

import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
const LandingPage = () => {
  const [showMoreIcons, setShowMoreIcons] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile screen width
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleMoreClick = () => {
    setShowMoreIcons(!showMoreIcons);
  };

  return (
    <>
      <section className="w-full h-auto">
        <video
          id="banner4-video"
          width="220"
          height="140"
          // poster="/assets/fallback.jpg"
          loop
          autoPlay
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100vh",
            position: "static",
          }}
        >
          <source
            src="https://maavatar-file.s3.ap-south-1.amazonaws.com/static/bannervedio.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="vedioheader">
          <img src="/favicon.png" alt="Favicon" className="favicon-flip" />
          <div className="headericons">
            <a
              href="https://x.com/MaAvatarmeta"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter />
              
            </a>
            <a
              href="https://discord.com/invite/35HZKUwqym"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LiaDiscord />
            </a>
            <a
              href="https://t.me/maavatar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoPaperPlaneOutline />
            </a>
            <a>
              <CgMoreO
                onClick={handleMoreClick}
                style={{ cursor: "pointer" }}
              />
            </a>

            {showMoreIcons && (
              <div className="more-icons">
                <a
                  href="https://medium.com/@maavatar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PiMediumLogo className="icon" />
                </a>
                <a
                  href="https://www.instagram.com/maavatar_official/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="icon" />
                </a>

                <a
                  href="https://www.linkedin.com/company/maavatar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="icon" />
                </a>
                <a
                  href="https://www.facebook.com/MaAvatar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare className="icon" />
                </a>
              </div>
            )}
          </div>
        </div>
        <a href="https://zealy.io/cw/maavatar" target="_blank" class="btn-zelle">
          <img
            src="https://ugc.production.linktr.ee/66c0dc83-ebdd-4561-a2c8-c5ca31a0ca3a_zealy-2.png?io=true&size=thumbnail-stack-v1_0"
            alt=""
          />
          <span>Join Zealy</span>
        </a>

        <div className="videosec">
          <div className="containe">
            <div className="content">
              <img className="title" src="/assets/MAAV_FONT[1].png" alt="" />
              <p className="description">
                The #1 Metaverse Dating Platform in the World
              </p>
              <div class="container">
                <div class="btn-shine">
                  <span>COMING SOON</span>
                </div>
              </div>
              {/* <span className="subtitle">COMING SOON</span> */}
            </div>
            
          </div>
          <a href="https://www.maavatar.io/waitlist" target="_blank" className="join-button">
            JOIN THE WAITLIST

          </a>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
