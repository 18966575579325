import "../../scss/_campaign.scss";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import React, { useEffect, useState } from "react";
import leaderboard_data from "./leaderboard.json";
import "animate.css";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { LiaDiscord } from "react-icons/lia";
import { PiMediumLogo } from "react-icons/pi";
import { CgMoreO } from "react-icons/cg";
import { IoLogoTwitter } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import "../../scss/landing.scss";
import Profile from "./Profile";

const CardContainer = ({ children }) => {
  return (
    <React.Fragment>
      <div id="card_container">{children}</div>
    </React.Fragment>
  );
};

const Card = ({
  imgLink = "",
  content1 = "",
  content2 = "",
  content3 = "",
}) => {
  return (
    <React.Fragment>
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={imgLink} alt="image" height={450} />
          </div>
          <div className="flip-card-back">
            <ul
              style={{
                textAlign: "start",
                width: "100%",
                listStyleType: "circle",
                fontSize: "13px",
              }}
            >
              <li style={{ textAlign: "start", listStyleType: "disc" }}>
                {content1}
              </li>
              <br />
              <li style={{ textAlign: "start", listStyleType: "disc" }}>
                {content2}
              </li>
              <br />
              <li style={{ textAlign: "start", listStyleType: "disc" }}>
                {content3}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Campaign = () => {
  const [showMoreIcons, setShowMoreIcons] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [INDEX_NO, setINDEX_NO] = useState("");

  const options = {
    leaderboard: false,
    rewards: false,
    rules: false,
  };

  const [tab, setTab] = useState({
    leaderboard: true,
    rewards: false,
    rules: false,
  });

  const handleMoreClick = () => {
    setShowMoreIcons(!showMoreIcons);
  };

  function handleOpenProfile(index) {
    setINDEX_NO(index);
    setToggleProfile(true);
  }

  return (
    <>
      {toggleProfile && (
        <Profile
          toggleProfile={toggleProfile}
          setToggleProfile={setToggleProfile}
          index={INDEX_NO}
        />
      )}
      <div className="vedioheader">
        <img src="/favicon.png" alt="Favicon" className="favicon-flip" />
        <div className="headericons">
          <a
            className="animate__animated animate__bounceInRight"
            href="https://x.com/MaAvatarmeta"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoTwitter />
          </a>
          <a
            className="animate__animated animate__bounceInRight"
            href="https://discord.com/invite/35HZKUwqym"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LiaDiscord />
          </a>
          <a
            className="animate__animated animate__bounceInRight"
            href="https://t.me/maavatar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoPaperPlaneOutline />
          </a>
          <a className="animate__animated animate__bounceInRight">
            <CgMoreO onClick={handleMoreClick} style={{ cursor: "pointer" }} />
          </a>

          {showMoreIcons && (
            <div className="more-icons">
              <a
                className="animate__animated animate__bounceInRight"
                href="https://medium.com/@maavatar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PiMediumLogo className="icon" />
              </a>
              <a
                className="animate__animated animate__bounceInLeft"
                href="https://www.instagram.com/maavatar_official/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="icon" />
              </a>

              <a
                className="animate__animated animate__bounceInRight"
                href="https://www.linkedin.com/company/maavatar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="icon" />
              </a>
              <a
                className="animate__animated animate__bounceInLeft"
                href="https://www.facebook.com/MaAvatar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare className="icon" />
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className="page"
        style={toggleProfile ? { filter: "blur(4px)" } : {}}
      >
        <div className="container">
          <div className="banner-container">
            <img
              className="mybanner"
              src={
                (tab.leaderboard && "/campaign/Artboard1.png") ||
                (tab.rewards && "/campaign/Artboard2.png") ||
                (tab.rules && "/campaign/Artboard3.png")
              }
              style={{ cursor: "pointer", width: "80%" }}
              alt="Banner"
            />
          </div>

          <div className="center">
            <div className="display-content" style={{ width: "100%" }}>
              <div
                className="center-tabs animate__animated animate__bounceInLeft"
                style={{
                  display: "grid",
                  placeItems: "center",
                  marginBottom: "50px",
                }}
              >
                <div className="tabs">
                  <Link
                    to="#"
                    className="tab-link"
                    onClick={() => {
                      setTab({ ...options, leaderboard: true });
                    }}
                    style={
                      tab.leaderboard
                        ? { boxShadow: "inset 0px 0px 20px 5px #777" }
                        : {}
                    }
                  >
                    Leaderboard
                  </Link>
                  <Link
                    to="#"
                    className="tab-link"
                    onClick={() => {
                      setTab({ ...options, rewards: true });
                    }}
                    style={
                      tab.rewards
                        ? { boxShadow: "inset 0px 0px 20px 5px #777" }
                        : {}
                    }
                  >
                    Rewards
                  </Link>
                  <Link
                    to="#"
                    className="tab-link"
                    onClick={() => {
                      setTab({ ...options, rules: true });
                    }}
                    style={
                      tab.rules
                        ? { boxShadow: "inset 0px 0px 20px 5px #777" }
                        : {}
                    }
                  >
                    Rules
                  </Link>
                </div>
              </div>
              {tab.leaderboard && (
                <div className="leaderboard">
                  <table style={{ width: "80%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th>RANK</th>
                        <th>PLAYER</th>
                        <th>BADGE</th>
                        <th>XP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaderboard_data &&
                        leaderboard_data.map((item, index) => {
                          return (
                            <tr
                              onClick={() => handleOpenProfile(index)}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <td className="animate__animated animate__fadeInLeftBig">
                                {item?.rank === "1" && (
                                  <img
                                    src="/campaign/Medals1.png"
                                    alt="image"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {item?.rank === "2" && (
                                  <img
                                    src="/campaign/Medals2.png"
                                    alt="image"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {item?.rank === "3" && (
                                  <img
                                    src="/campaign/Medals3.png"
                                    alt="image"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {item.rank > 3 && item.rank}
                              </td>
                              <td className="animate__animated animate__fadeInRightBig">
                                <div
                                  style={{
                                    position: "relative",
                                    margin: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                    width: "250px",
                                    // background:"red",
                                    paddingLeft: 15,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  <img
                                    src={item?.profile_Image}
                                    alt="image"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "start",
                                      justifyContent: "space-between",
                                      flexDirection: "column",
                                      width: "60%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.player}
                                    </p>
                                    <Link
                                      target="_blank"
                                      to={item?.twitter}
                                      style={{ color: "#fff" }}
                                    >
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          columnGap: "5px",
                                          background: "#383838",
                                          borderRadius: "24px",
                                          fontSize: "11px",
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                          paddingTop: "2px",
                                          paddingBottom: "2px",
                                        }}
                                      >
                                        <img
                                          src="/campaign/Footer-items/twitter.svg"
                                          width={15}
                                          alt="logo"
                                        />
                                        {item?.twitter_username}
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                              <td className="animate__animated animate__fadeInTopLeft">
                                {item?.badge === "maav og" && (
                                  <img
                                    src="/campaign/maav_og.png"
                                    alt="image"
                                    width={70}
                                    height={70}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}

                                {item?.badge === "maav goat" && (
                                  <img
                                    src="/campaign/maav_goat.png"
                                    alt="image"
                                    width={65}
                                    height={65}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}

                                {item?.badge === "maav hunters" && (
                                  <img
                                    src="/campaign/maav_hunters.png"
                                    alt="image"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}

                                {item?.badge === "maav darlings" && (
                                  <img
                                    src="/campaign/maav_darlings.png"
                                    alt="image"
                                    width={50}
                                    height={50}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}

                                {item?.badge === "maav stars" && (
                                  <img
                                    src="/campaign/maav_stars.png"
                                    alt="image"
                                    width={65}
                                    height={65}
                                    style={{
                                      borderRadius: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </td>
                              <td className="animate__animated animate__fadeInTopRight">
                                {item?.xp}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              {tab.rewards && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <CardContainer>
                    <Card
                      imgLink="/campaign/rewards1.png"
                      content1="Unlock amazing rewards with our Loot Boxes! Each box
                          gives you special recognition in the community, with a
                          unique role and badge. Enjoy VIP early access to new
                          drops, priority updates on developments, and exclusive
                          offers."
                      content2="Participate in exclusive events, forums, and Q&A
                          sessions with our team and top community members.
                          Plus, get featured in our community spotlight,
                          showcasing your achievements and rewards."
                      content3="Thank you for joining the challenge and making it a
                          success. We can’t wait for you to discover the
                          surprises inside your $MAAV Loot Box."
                    />
                    <Card
                      imgLink="/campaign/rewards2.png"
                      content1="Unlock the magic of our NFT Mystery Boxes! Each box is
                          packed with ultra-rare, limited-edition NFTs that not
                          only boost your in-game experience but elevate your
                          status within the community."
                      content2=" Be the first to access exclusive drops with VIP early
                          access, and stay ahead with priority news on new
                          features and limited offers."
                      content3="Claim Whitelist-only bonuses, enter thrilling
                          giveaways, and secure invites to exclusive events
                          crafted just for you."
                    />
                  </CardContainer>
                </div>
              )}
              {tab.rules && (
                <div className="rules">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      rowGap: "40px",
                    }}
                  >
                    <img
                      className="animate__animated animate__flipInY"
                      src="/campaign/img.png"
                      alt="image"
                      width={300}
                      style={{ cursor: "pointer" }}
                    />
                    <div
                      style={{
                        height: "230px",
                        width: "230px",
                        background: "none",
                      }}
                    ></div>
                    <img
                      className="animate__animated animate__flipInY"
                      src="/campaign/img2.png"
                      width={300}
                      style={{ cursor: "pointer" }}
                    />
                    <div
                      style={{
                        height: "230px",
                        width: "230px",
                        background: "none",
                      }}
                    ></div>
                    <div
                      style={{
                        height: "230px",
                        width: "230px",
                        background: "none",
                      }}
                    ></div>
                    <img
                      className="animate__animated animate__flipInY"
                      src="/campaign/img3.png"
                      width={300}
                      style={{ cursor: "pointer" }}
                    />
                    <div
                      style={{
                        height: "230px",
                        width: "230px",
                        background: "none",
                      }}
                    ></div>
                    <img
                      className="animate__animated animate__flipInY"
                      src="/campaign/img4.png"
                      width={300}
                      style={{ cursor: "pointer" }}
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h1 style={{ marginTop: "10px", marginBottom: "10px" }}>
                        never miss a drop!
                      </h1>
                      <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                        Subscribe to super-exclusive drop list and be the first
                        to know about upcoming drops
                      </p>
                      <form style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <input
                          type="email"
                          required
                          placeholder="Enter your email address"
                          style={{
                            height: "40px",
                            width: "400px",
                            borderRadius: "24px",
                            outline: "none",
                            border: "none",
                            background: "#383838",
                            color: "#fff",
                          }}
                        />{" "}
                        <input
                          type="submit"
                          value="Subscribe"
                          style={{
                            height: "40px",
                            width: "150px",
                            borderRadius: "24px",
                            outline: "none",
                            border: "none",
                            background:
                              "linear-gradient(90deg,#D58EEC, #20BDFF, #8244E8)",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Campaign;
