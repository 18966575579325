import { useCallback, useEffect, useState } from "react";

function Waitlist() {
  useEffect(() => {
    window.location.href = "https://waitlist.maavatar.io";
  }, []);

  return null;
}

export default Waitlist;
